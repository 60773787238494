
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Products } from '@one/types'
  import { CreateElement, RenderContext, VNode } from 'vue'
  import { DefaultProps } from 'vue/types/options'

  @Component({
    // @ts-ignore
    functional: true,
  })
  export default class OneProductTechnicalData extends Vue {
    @Prop({
      required: false,
      type: Array,
    })
    technicalAttributes?: Array<Products.MultivalueAttribute>;

    render(createElement: CreateElement, context: RenderContext<DefaultProps>): VNode|null {
      if (!context.props.technicalAttributes) { return null }
      const attributesIsNotLogicalFuture = (element: Products.MultivalueAttribute) => {
        return element.valueType !== Products.AttributeType.LOGIC
      }
      const iconCorrectAndCancel = (values: Array<string>) => {
        return values && values.map((value) => {
          return createElement('i', {
            class: ['ml-2', (value === 'true') ? 'icon-correct' : 'icon-cancel'],
          })
        })
      }
      const parserAlphanumericalAttributes = (values: Array<Products.AttributeValue>) => {
        return values && values.map(({ value }) => ` ${value}`)
      }
      const parserLogicalAttributes = (values: Array<Products.AttributeValue>) => {
        return values && values.map(({ value }) => value)
      }
      const parserNumericalAttributes = (values: Array<Products.AttributeValue>) => {
        return values && values.map(({ value, unitDescription }) => {
          return !unitDescription
            ? value
            : ` ${value} ${unitDescription}`
        })
      }
      const parsers = {
        [Products.AttributeType.ALPHANUMERIC]: parserAlphanumericalAttributes,
        [Products.AttributeType.NUMERIC]: parserNumericalAttributes,
        [Products.AttributeType.LOGIC]: parserLogicalAttributes,
      }
      const generateAttributes = () => {
        return context.props.technicalAttributes.map((attribute: Products.MultivalueAttribute, index: number) => {
          return createElement('b-col', {
            class: ['d-flex', 'justify-content-between', 'my-1', 'py-1'],
            props: {
              cols: '*',
              md: '6',
              key: index,
            },
          }, [
            createElement('span', attribute.name),
            attributesIsNotLogicalFuture(attribute)
              ? createElement('span', {
                class: ['ml-1', 'font-weight-bold', 'text-right'],
                domProps: {
                  textContent: parsers[attribute.valueType](attribute.values),
                },
              })
              : createElement('div', [iconCorrectAndCancel(parsers[attribute.valueType](attribute.values))]),
          ])
        })
      }
      return createElement('b-row', {
        class: ['one-product-technical-data', 'w-100', 'm-0', 'py-1', 'text'],
      }, generateAttributes())
    }
  }
